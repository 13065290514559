<template>
  <div class="moduleListWrap">
    <div
      class="listModule"
      v-for="(item, index) in imgList"
      :key="index"
      @click="jumpDetail(item.spuId)"
    >
      <img :src="item.imgUrl" alt="" />
      <p class="itemPrize">
        优惠价:<span>{{ "￥" +detailPrice(item.salePrice,100)}}</span>
      </p>
    </div>
  </div>
</template>
<style scoped lang='scss'>
.moduleListWrap {
  width: 6.9rem;
  margin-left: 0.3rem;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  .listModule {
    // width: 1.5rem;
    // height: 2rem;
    // border-radius: 0.15rem;
    margin-right: 10px;
    margin-bottom: 25px;
    overflow: hidden;
    flex-shrink: 0;
    width: 165px;
    height: 219px;
    background: linear-gradient(270deg, #faa25a 0%, #fc3a39 100%);
    border-radius: 16px;
    img {
      display: block;
      width: 165px;
      height: 165px;
    }
    .itemPrize {
      // height: 0.5rem;
      // line-height: 0.5rem;
      // font-size: 0.24rem;
      // color: #;
      // text-align: right;
      height: 50px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      span {
        display: inline-block;
        height: 50px;
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        color: #ffffff;
        line-height: 50px;
      }
    }
  }
  .listModule:nth-of-type(4n + 4) {
    margin-right: 0rem;
  }
}
</style>
<script>
export default {
  data() {
    return {
      imgList: [],
    };
  },
  mounted() {
    this.getImgListData();
  },
  methods: {
    getImgListData() {
      this.request
        .get("/api/banner/index/info", {
          type: 7,
          count: 1,
          sceneNo: this.$route.query.scence,
          channelNo: localStorage.getItem("channel"),
        })
        .then((res) => {
          console.log(res);
          if (res) {
            const { type, bannerProductBaseInfos } = res;
            if (type && bannerProductBaseInfos) {
               this.imgList = bannerProductBaseInfos; 
            }
          }
        });
    },
    jumpDetail(spuId) {
      this.$router.push({
        path: "/h/scm/mall/goodsDetail",
        query: {
          sceneNo: this.$route.query.scence,
          spuId: spuId,
        },
      });
    },
     detailPrice(arg1,arg2) {
      console.log(arg1+'======'+arg2)
       var t1 = 0,
        t2 = 0,
        r1, r2;
        try {
          t1 = arg1.toString().split(".")[1].length
        } catch (e) {}
        try {
          t2 = arg2.toString().split(".")[1].length
        } catch (e) {}
        r1 = Number(arg1.toString().replace(".", ""));
        r2 = Number(arg2.toString().replace(".", ""));
        if (r2 == 0) {
          return Infinity;
        } else {
          return (r1 / r2) * Math.pow(10, t2 - t1);
        }
    },
  },
};
</script>