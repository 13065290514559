<template>
    <div class="userCenter">
        <div class="userPageHeader">
            <div class="userData">
                尊敬的{{userNumber}}用户，你好
            </div>
        </div>
        <div class="tabModule">
            <div class="tabTitle">我的订单</div>
            <div class="tabItems">
                <div class="firstWrap" @click="jumpAllList('null')">
                    <div class="allList"></div>
                    <p class="boxName">全部订单</p>
                </div>
                 <div class="boxWrap" @click="jumpAllList(0)">
                    <div class="wfk"></div>
                    <p class="boxName">待付款</p>
                </div>
                 <div class="boxWrap" @click="jumpAllList(1)">
                    <div class="dfh"></div>
                    <p class="boxName">待发货</p>
                </div>
                 <div class="boxWrap" @click="jumpAllList(3)">
                    <div class="dsh"></div>
                    <p class="boxName">待收货</p>
                </div>
                 <div class="boxWrap" @click='jumpYhq()'>
                    <div class="yhq"></div>
                    <p class="boxName">优惠券</p>
                </div>
            </div>
        </div>
        <swiper />
        <moduleList />
        <div style="width:100%;height:1.3rem"></div>
    </div>
</template>
<script>
import swiper from "./component/swiper";
import moduleList from './component/moduleList.vue'
export default {
    name: "mineSwiper",
    data() {
        return {
            userNumber:localStorage.getItem('userNumber')|| ''
         }
    },
    components: {
        swiper,
        moduleList
    },
    methods:{
        jumpAllList(index){
           this.$router.push({
                path: "/h/scm/order/orderList",
                    query: {
                    from: "hotSale",
                    sceneNo:this.$route.query.scence,
                    needIndex:index
                }
            }); 
        },
        jumpYhq(){
        //     this.$toast({
        //         message: "敬请期待",
        //         position: "bottom"
        //      });
        // return;
            this.$router.push({
                path: "/h/scm/discount",
                    query: {
                    sceneNo:this.$route.query.scence
                }
            }); 
        }
    }
}
</script>
<style scoped lang='scss'>
   .userCenter{
       width: 750px;
       min-height: 100vh;
       background: #f5f5f5;
       overflow: hidden;
       .userPageHeader{
           width: 750px;
           height: 399px;
           background: url('~@/assets/userCenter/userHeader.png') no-repeat;
           background-size: 100% 100%;
           overflow: hidden;
           .userData{
               width: 523px;
               height: 104px;
                font-size: 40px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 52px;
                margin-left: 30px;
                margin-top: 100px;
           }
       }
        .tabModule{
            width: 690px;
            height: 242px;
            background: #FFFFFF;
            box-shadow: 0px 4px 16px 0px rgba(106, 106, 106, 0.08);
            border-radius: 16px;
            margin:0 auto;
            margin-top: -130px;
            overflow: hidden;
            .tabTitle{
                // width: 128px;
                height: 45px;
                font-size: 32px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 45px;
                margin-top: 26px;
                margin-left: 20px;
            }
            .tabItems{
                width: 650px;
                margin-left: 20px;
                display: flex;
                display: -webkit-flex;
                justify-content: space-between;
                .firstWrap{
                    .allList{
                        width: 40px;
                        height: 48px;
                        // margin-left: 28px;
                        // margin-right: 60px;
                        margin: 0 auto;
                        margin-top: 34px;
                        margin-bottom: 21px;
                        background: url('~@/assets/userCenter/allList.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    .boxName{
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 33px;
                    }
                }
                .boxWrap{
                    .wfk{
                        width: 56px;
                        height: 38px;
                        margin: 0 auto;
                        margin-top: 42px;
                        margin-bottom: 23px;
                        background: url('~@/assets/userCenter/dfk.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    .boxName{
                        font-size: 24px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        line-height: 33px;
                    }
                    .dfh{
                        width: 50px;
                        height: 42px;
                        margin: 0 auto;
                        margin-top: 38px;
                        margin-bottom: 23px;
                        background: url('~@/assets/userCenter/dfh.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    .dsh{
                        width: 53px;
                        height: 44px;
                        margin: 0 auto;
                        margin-top: 38px;
                        margin-bottom: 21px;
                        background: url('~@/assets/userCenter/dsh.png') no-repeat;
                        background-size: 100% 100%;
                    }
                    .yhq{
                        width: 53px;
                        height: 34px;
                        margin: 0 auto;
                        margin-top: 42px;
                        margin-bottom: 27px;
                        background: url('~@/assets/userCenter/yhq.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
   }
</style>