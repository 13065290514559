<template>
  <div class="swiperWrap">
    <div class="mineSwiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in imgList" :key="'topImg' + index">
          <img :src="item.imgUrls[0]"  alt="" />
        </div>
    
      </div>
      <div class="swiperpagination"></div>
    </div>
  </div>
</template>

<script>

export default {
  name: "swiper",
  data() {
    return {
      imgList: [{
       imgUrls:[]
      }
      ]
    };
  },
  created() {},
  beforeMount() {},
  mounted() {
    // this.getFloors();
    //  this.initSwipe();
    this.getSwiperData();
  },
  methods: {
    getSwiperData(){
       this.request.get('/api/banner/index/info',{
        type:6,
        count:1,
        sceneNo:this.$route.query.scence,
        channelNo:localStorage.getItem('channel')
      }).then(res =>{
        console.log(res)
        if(res){
          const {bannerBaseInfos} = res;
          this.imgList = bannerBaseInfos;
          this.$nextTick(() => {
            this.initSwipe();
          });
        }
      })
    },
    getFloors(){
      this.$get('/api/n/aid/taobaoke/floor/goods/list',{floorTypeId:1}).then(res=>{
          const {data,code} = res;
          if(code=='0'){
            const {goodsList} = data;
              this.imgList = goodsList;
              this.$nextTick(() => {
                   this.initSwipe();
               });
          }
      })
    },
    // 初始化轮播图
    initSwipe(){
      var that = this;
      new this.$Swiper(".mineSwiper-container", {
        delay: 5000,
        autoplay: {
          disableOnInteraction: false
        },
        loop: true,
        // 如果需要分页器
        pagination: {
          el: ".swiperpagination"
        },
        resistanceRatio: 0,
         on:{
          click: function(){
             console.log(this.realIndex)
             that.jump(this.realIndex)
          },
        },

      });
    },
    jump(index){
      console.log(index)
      console.log(this.imgList)
      const {spuId,url} = this.imgList[index];
      window.location.href = url;
      // if(spuId){
      //   // window.location.href = clickUrl;
      //   this.$router.push({
      //   path: "/h/scm/mall/goodsDetail",
      //   query: {
      //     sceneNo:this.$route.query.scence,
      //     spuId: spuId
      //   }
      // });
      // }else{
      //   // this.$router.push(pathUrl)
      //    this.$router.push({
      //   path: "/h/scm/virtual/goodsDetail",
      //   query: {
      //     sceneNo:this.$route.query.scence,
      //     id:1
      //   }
      // });
      // }
    
    }
  }
};
</script>
<style lang="scss" scoped>
.swiperWrap {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;
}
.mineSwiper-container {
  height: 1.8rem;
  margin: 0 auto;
  width: 6.9rem;
  // box-shadow: -0.07rem -0.07rem 0.18rem rgba($color: #ef0200, $alpha: 0.34);
  border-radius: 0.16rem;
  overflow: hidden;

  .swiper-slide {
    width: 6.9rem;
    height: 1.8rem;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
  .swiper-pagination {
    bottom: 0.14rem;
    .swiper-pagination-bullet {
      width: 0.06rem;
      height: 0.06rem;
      border-radius: 0.06rem;
      margin: 0 0.04rem 0 0.03rem;
      background: #fff;
      opacity: 0.5;
      &.swiper-pagination-bullet-active {
        width: 0.16rem;
        opacity: 1;
      }
    }
  }
}
</style>